import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import logo from "../../static/images/logo.png";

const Nav = ({ siteTitle }) => {
  let offset;
  if (typeof window !== "undefined") {
    offset = window.pageYOffset;
  } else {
    offset = 0;
  }
  let [pos, setPos] = useState(offset);
  let [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      let temp = window.pageYOffset;
      setVisible(pos < 540);
      setPos(temp);
    };
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <header>
      <nav className="navbar navbar-light bg-light shadow fixed-top p-0">
        <div className="navbar-header">
          <div className="container">
            <ul className="info order-lg-1 order-2">
              <li>
                <a href="mailto:contacto@hevasa.com">
                  <i className="icon-mail-1 contact" /> contacto@hevasa.com
                </a>
              </li>
              <li>
                <i className="icon-mobile contact" /> (55) 1563 3024
              </li>
            </ul>
            <ul className="social order-lg-2 order-1">
              <li>
                <a href="https://www.facebook.com/MCProyectosTelecom/">
                  <i className="icon-s-facebook" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/mcproyectostelecom">
                  <i className="icon-s-linkedin" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <nav
        className={
          "navbar navbar-expand-lg fixed-top fixed-top-2 " +
          (visible ? "transparent" : " ")
        }
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="logo" className="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse nav-top"
            id="navbarResponsive"
          >
            <ul
              className={
                "navbar-nav ml-auto " + (visible ? "navbarHidden" : " ")
              }
            >
              <li>
                <ScrollLink
                  activeClass="active"
                  className="nav-link"
                  to="inicio"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={2500}
                >
                  INICIO
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  activeClass="active"
                  className="nav-link"
                  to="nosotros"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={2500}
                >
                  NOSOTROS
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  activeClass="active"
                  className="nav-link"
                  to="servicios"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={2500}
                >
                  SERVICIOS
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  activeClass="active"
                  className="nav-link"
                  to="clientes"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={2500}
                >
                  NUESTRO TRABAJO
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  activeClass="active"
                  className="nav-link"
                  to="contactanos"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={2500}
                >
                  CONTACTO
                </ScrollLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
Nav.propTypes = {
  siteTitle: PropTypes.string
};

Nav.defaultProps = {
  siteTitle: ``
};

export default Nav;
