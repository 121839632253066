/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Nav from "./nav";
import logo from "../../static/images/logo-hevasa-white.png";
import "../../static/styles/main.scss";
import { Link as ScrollLink } from "react-scroll";
// import iconTwitter from "../../static/images/icon-twitter.svg"
// import iconLinkedin from "../../static/images/icon-linkedin.svg"
// import iconGitHub from "../../static/images/icon-github.svg"
// import iconInstagram from "../../static/images/icon-instagram.svg"
import "bootstrap";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Nav siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer className="bg-blue-footer">
          <div className="container inner">
            <div className="row a">
              <div className="col-lg-4 col-md-4 my-auto">
                <a href="/">
                  <img className="logo img-intext" src={logo} alt="logo" />
                </a>
              </div>
              <div className="col-lg-4 col-md-4 my-auto">
                <p>TODOS LOS DERECHOS RESERVADOS. HEVASA 2019. </p>
              </div>
            </div>
          </div>
        </footer>{" "}
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
